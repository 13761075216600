<template lang="">
    <div class="atlas-map-wrapper">
        <Loader v-if="isLoading"/>
        <atlas-map ref="atlasMap" v-if="gisTokenloaded && layersLoaded"/>
        <transition name="slide-fade">
            <left-sidebar v-show="isLeftSidebarVisible && !showNewAreaSection" id="left-sidebar" ref="left-side-bar"/>
        </transition>
        <div class="new-area-sidebar" v-if="showNewAreaSection">
            <NewAreaSidebar @hideSidebar="hideSidebar"/>
         </div>
        <transition name="slide-fade-right">
            <right-sidebar v-if="isRightSidebarVisible" id="right-sidebar"/>
        </transition>
        <transition name="slide-fade-right">
            <comments-sidebar v-if="isCommentsSidebarVisible" id="comments-sidebar"/>
        </transition>
        <layers-visibility-settings-modal
            ref="layers-visibility-settings-modal"
        />
    </div>
</template>
<script>

import LeftSidebar from '@/modules/atlas/components/LeftSidebar';
import RightSidebar from '@/modules/atlas/components/RightSidebar';
import CommentsSidebar from '@/modules/atlas/components/CommentsSidebar';
import NewAreaSidebar from '@/modules/atlas/components/interest-areas-manager/NewAreaSidebar';
import LayersVisibilitySettingsModal from '@/modules/atlas/components/visibility-settings/LayersVisibilitySettingsModal';
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'Atlas',
    components: {
        LeftSidebar,
        RightSidebar,
        NewAreaSidebar,
        CommentsSidebar,
        LayersVisibilitySettingsModal,
        AtlasMap: () => import(/* webpackChunkName: "atlas" */ '@/modules/atlas/components/AtlasMap.vue'),
    },
    data() {
        return {
            leftSidebarWidth: null,
            gisTokenloaded: false,
            map: null,
            gisView: null,
        }
    },
    computed: {
        ...mapGetters({
            isLeftSidebarVisible: 'atlas/sidebar/isVisible',
            isInterestAreaVisible: 'atlas/sidebar/isInterestAreaVisible',
            isVisibiltySettingsVisible: 'atlas/sidebar/isVisibiltySettingsVisible',
            isNewAreaModalVisible: 'atlas/interest-areas/isNewAreaModalVisible',
            isRightSidebarVisible: 'atlas/layer-manager/isSublayerSidebarVisible',
            isCommentsSidebarVisible: 'atlas/comments/commentsVisible',
            systemLayers: 'atlas/layer-manager/systemLayers',
            layersLoaded:'atlas/layer-manager/layersLoaded',
            isAtlasPublicView: 'shared/isAtlasPublicView',
            loading: 'shared/loading',
            isStaff: 'auth/isStaff',
        }),
        showNewAreaSection() {
            return this.isInterestAreaVisible && this.isNewAreaModalVisible
        },
        isLoading() {
            return  this.isVisibiltySettingsVisible &&
                    (this.loading['visibility-settings/updateLayerVisibilityOrder'] ||
                    this.loading['atlas-layer-manager/get'])
        },
    },
    watch: {
        isVisibiltySettingsVisible(val) {
            if (val) this.$refs['layers-visibility-settings-modal'].show();
        }
    },
    methods: {
        ...mapActions({
            initSidebarState: 'atlas/sidebar/doInit',
            initLayersState: 'atlas/layer-manager/doInit',
            getGisToken: 'atlas/token/getGisToken',
            newInterestArea: 'atlas/interest-areas/newInterestArea',
            showNewInterestArea: 'atlas/interest-areas/showNewInterestArea',
            getSystemLayers: "atlas/layer-manager/getSystemLayers",
        }),
        hideSidebar() {
            this.newInterestArea(false);
            this.showNewInterestArea({status: false});
        },
    },
    async created() {
      // init state
      await this.initSidebarState();
      await this.initLayersState();
      // do fetch
      await this.getGisToken(this.isStaff);
      await this.getSystemLayers();

      this.gisTokenloaded = true;
    },
}
</script>
<style lang="scss">
    .atlas-map-wrapper {
        height: 100%;
        margin: 0 -12px;
        position: relative;
    }
    .slide-fade-enter-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to {
        transform: translateX(-100%);
    }
    .slide-fade-leave-to {
        opacity: 0;
    }
    .slide-fade-right-enter-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }
    .slide-fade-right-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-right-enter, .slide-fade-right-leave-to {
        transform: translateX(100%);
    }
    .slide-fade-right-leave-to {
        opacity: 0;
    }
    .new-area-sidebar {
        position: absolute;
        top: 0;
        height: 100%;
        width: 450px;
        background-color: #f2f3f7;
        border-right: 1px solid lightgray;
        margin-left: -3px;
        box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.16);
    }
</style>
